<template>
    <div class="news_detail">
        <Header/>
        <div class="banner">
            <div class="content">
                <User/>
                <div class="main">
                    <div class="head">
                        <div class="title">
                            新闻详情
                        </div>
                        <div class="bag">当前位置：
                            <el-breadcrumb separator-class="el-icon-arrow-right">
                                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                                <el-breadcrumb-item :to="{ path: '/news' }">新闻资讯</el-breadcrumb-item>
                                <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
                            </el-breadcrumb>
                        </div>
                        <div class="xian"></div>
                    </div>
                    <div class="detail">
                        <div class="title">{{list.title}}</div>
                        <div class="time">
                            发布时间： {{list.createTime}} 浏览次数： {{list.viewCount}}
                        </div>
                        <div v-html="list.content" class="text">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import User from "../components/User.vue"
import { GetNews } from '../api/list'
    export default {
        components: {
            Header,
            Footer,
            User
        },
        data() {
            return {
                list: ''
            }
        },
        created () {
            GetNews(this.$route.query.id).then(res=>{
                this.list=res.data.data
                if(res.data.msg=='登陆已过期'){
                        this.$alert('登录已过期请重新登录')
                        this.$router.push('/')
                    }
            })
        },
    }
</script>

<style  scoped>
@import url(../assets/css/News_detail.css);
</style>